import { createBrowserRouter, RouterProvider, Route, createRoutesFromElements } from 'react-router-dom';

import AppFrame from '../parts/app-frame';
import PlatformPark from '../frames/platform-park';
import SignUp from '../frames/sign-up';
import LogIn from '../frames/log-in';

import NotFound from './not-found';
import ParkDetail from '../frames/park-detail';
import Cover from '../frames/cover';
import ProfileDetail from '../frames/profile-detail';
import PasswordReset from '../frames/password-reset';
import PasswordForget from '../frames/password-forget';
import AccountSetting from '../frames/account-setting';

const AppRouter = () => {
    return (
        <RouterProvider router={router} />
    );
};


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' errorElement={<AppFrame display='notfound' />}>
            <Route element={<AppFrame display='cover' />} >
                <Route index element={<Cover />} />
            </Route>

            <Route path='parks' element={<AppFrame display='platform' />}>
                <Route path=':page' element={<PlatformPark />} />
                {/* <Route path=':locator' element={<ParkDetail />} /> */}
                {/* <Route path='' element={<NotFound />} /> */}
            </Route>

            <Route path='park' element={<AppFrame display='detail' />}>
                <Route path=':locator' element={<ParkDetail />} />
                <Route path='' element={<NotFound />} />
            </Route>

            <Route path='profile' element={<AppFrame display='detail' />}>
                <Route path=':locator' element={<ProfileDetail />} />
                <Route index element={<ProfileDetail />} />
            </Route>

            <Route path='account' element={<AppFrame display='account' />}>
                <Route path='signup' element={<SignUp />} />
                <Route path='login' element={<LogIn />} />
                <Route path='setting' element={<AccountSetting />} />
                <Route path='password-forget' element={<PasswordForget />} />
                <Route path='password-reset' element={<PasswordReset />} />
                <Route path='' element={<NotFound />} />
            </Route>


            {/* <Route path='*' element={<AppFrame display='notfound' />} /> */}
        </Route>
    )
);




export default AppRouter;