import AppRouter from './routes/app-router';
import './app.css';


const App = () => {

  return (

    <div className='App'>
      <AppRouter />
    </div>

  );
};



export default App;
