import { Link } from "react-router-dom";


import styles from './styles/at-logo.module.css';
import favicon from '../../assets/favicon.svg';
import { REACT_APP_TITLE } from "../../lib/variables";

const AtLogo = () => (
    <Link to='/' className={styles.wrapper}>
        <img src={favicon} alt='logo' />
        <h2>{REACT_APP_TITLE}</h2>
    </Link>
);

export default AtLogo;