



const NotFound = () => {

  return (
    <div className='NotFound'>

      Not Found
    </div>
  );
};


export default NotFound;
