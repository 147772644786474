import { BACKEND_URL } from "./variables";

export const SESSION_STORAGE_PROFILE = 'profile_session';



export const endpoint = {
   option: `${ BACKEND_URL }`
};


// frontend fetch url --- backend route param query

/*

[option]    --- `${BACKEND_URL}/options/set${name}?term=${term}`  --- router.get('/set:name, ...)           --- const { name } = req.params; const { term } = req.query;
[detail]    --- `${BACKEND_URL}/details/${type}/${locator}`       --- router.get('/park/:locator', ...)
[account]   --- `${BACKEND_URL}/account/signup`
[favorite]
[park]
[present]
[token]




*/